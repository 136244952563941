(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/account-selector/assets/javascripts/helpers/show-increase-warning.js') >= 0) return;  svs.modules.push('/components/marketplace/account-selector/assets/javascripts/helpers/show-increase-warning.js');

'use strict';

const {
  Confirm,
  branding,
  message,
  api
} = svs.ui.dialog;
const showIncreaseWarning = (shares, balance, callback) => {
  const dialog = new Confirm({
    branding: branding.SPORT,
    icon: 'balance',
    title: "".concat(shares, " andel").concat(shares !== 1 ? 'ar' : '', " till har k\xF6pts!"),
    message: [{
      type: message.TEXT,
      text: "Det finns nu ".concat(balance, " kr att spela f\xF6r. <br /> Du kan g\xE5 tillbaka och \xE4ndra raden eller forts\xE4tta till betalningen.")
    }],
    actions: [{
      title: 'Avbryt'
    }, {
      title: 'Fortsätt till betalning',
      callback: () => {
        dialog.close();
        callback();
      }
    }]
  });
  api.add(dialog);
};
setGlobal('svs.components.marketplace.accountSelector.helpers.showIncreaseWarning', showIncreaseWarning);

 })(window);