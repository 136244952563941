(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/account-selector/assets/javascripts/commission-selector.js') >= 0) return;  svs.modules.push('/components/marketplace/account-selector/assets/javascripts/commission-selector.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  accountSelector
} = svs.components.marketplace;
const {
  recountRetailBalance
} = svs.components.marketplace.retailCommissionCounter;
const countBalance = (soldShares, sharePrice, commission) => recountRetailBalance(soldShares, sharePrice, commission);
const changeCommissionBalance = (newCommission, newBalance) => {
  const currentAccount = accountSelector.helpers.getCurrentAccount();
  const {
    observer
  } = accountSelector.helpers;
  const newAccount = _objectSpread(_objectSpread({}, currentAccount), {}, {
    selectedCommissionPercent: newCommission || 0,
    balance: newBalance
  });
  const accounts = observer.getValue().accounts.filter(acc => acc.activatedDrawId !== newAccount.activatedDrawId);
  accounts.push(newAccount);
  observer.notify({
    accounts
  });
};
const CommissionSelector = () => {
  const currentAccount = accountSelector.helpers.getCurrentAccount() || {};
  const {
    commissionPercent
  } = currentAccount;
  const selectedCommissionPercent = typeof currentAccount.selectedCommissionPercent === 'number' ? currentAccount.selectedCommissionPercent : currentAccount.commissionPercent;
  return React.createElement("div", {
    className: "commission-selector padding-top padding-bottom"
  }, React.createElement("p", {
    className: "fc-grey-500"
  }, "OMBUDSAVGIFT (S\xC4NK F\xD6R ATT H\xD6JA SALDOT)"), React.createElement("div", {
    className: "select-label"
  }, React.createElement("select", {
    className: "select select-default",
    onChange: e => changeCommissionBalance(Number(e.target.value), countBalance(currentAccount.soldShares, currentAccount.sharePrice, Number(e.target.value))),
    value: selectedCommissionPercent
  }, React.createElement("option", {
    value: "0"
  }, "0\xA0%"), React.createElement("option", {
    disabled: commissionPercent < 1,
    value: "1"
  }, "1\xA0%"), React.createElement("option", {
    disabled: commissionPercent < 2,
    value: "2"
  }, "2\xA0%"), React.createElement("option", {
    disabled: commissionPercent < 3,
    value: "3"
  }, "3\xA0%"), React.createElement("option", {
    disabled: commissionPercent < 4,
    value: "4"
  }, "4\xA0%"), React.createElement("option", {
    disabled: commissionPercent < 5,
    value: "5"
  }, "5\xA0%"), React.createElement("option", {
    disabled: commissionPercent < 6,
    value: "6"
  }, "6\xA0%"), React.createElement("option", {
    disabled: commissionPercent < 7,
    value: "7"
  }, "7\xA0%"), React.createElement("option", {
    disabled: commissionPercent < 8,
    value: "8"
  }, "8\xA0%"), React.createElement("option", {
    disabled: commissionPercent < 9,
    value: "9"
  }, "9\xA0%"), React.createElement("option", {
    disabled: commissionPercent < 10,
    value: "10"
  }, "10\xA0%"))));
};
setGlobal('svs.components.marketplace.accountSelector.CommissionSelector', CommissionSelector);
setGlobal('svs.components.marketplace.accountSelector.countBalance', countBalance);
setGlobal('svs.components.marketplace.accountSelector.changeCommissionBalance', changeCommissionBalance);

 })(window);