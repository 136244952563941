(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/account-selector/assets/javascripts/helpers/get-current-account.js') >= 0) return;  svs.modules.push('/components/marketplace/account-selector/assets/javascripts/helpers/get-current-account.js');

'use strict';

const {
  url
} = svs.utils;
const {
  marketplace
} = svs.components;
setGlobal('svs.components.marketplace.accountSelector.helpers.getCurrentAccount', accounts => {
  const currentActivatedDrawId = url.queryString.activatedDrawId;
  return (accounts || marketplace.accountSelector.helpers.observer.getValue().accounts).find(acc => acc.activatedDrawId === currentActivatedDrawId);
});

 })(window);