(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/account-selector/assets/javascripts/use-account.js') >= 0) return;  svs.modules.push('/components/marketplace/account-selector/assets/javascripts/use-account.js');
"use strict";


const {
  useEffect,
  useState
} = React;
const {
  marketplace
} = svs.components;
const {
  roles
} = svs.core.userSession;
const {
  Confirm,
  branding,
  message,
  api,
  type
} = svs.ui.dialog;
const {
  queryString
} = svs.utils.url;
const logger = svs.core.log.getLogger('account-selector');
const useAccount = (drawNumber, productId) => {
  const [marketplaceAccount, setMarketplaceAccount] = useState(undefined);
  const [accountBalance, setAccountBalance] = useState(0);
  const [accounts, setAccounts] = useState(0);
  const noAccountWarning = () => {
    const dialog = new Confirm({
      branding: branding.SPORT,
      icon: 'target',
      type: type.ERROR,
      title: 'Något gick fel!',
      message: [{
        type: message.TEXT,
        text: "Kopplingen till spela tillsammans f\xF6rsvann."
      }],
      actions: [{
        title: 'Okej',
        callback: () => {
          dialog.close();
        }
      }]
    });
    api.add(dialog);
  };
  useEffect(() => {
    if (!marketplaceAccount || marketplaceAccount && accountBalance !== marketplaceAccount.balance) {
      svs.components.account_balance.get(false, balance => {
        setAccountBalance(parseInt(balance, 10));
      });
    }
  }, [accountBalance, marketplaceAccount]);
  useEffect(() => {
    const onAccountSelectorDataChange = _ref => {
      let {
        currentAccount,
        accounts
      } = _ref;
      setMarketplaceAccount(currentAccount);
      setAccounts(accounts);
      const balance = currentAccount ? currentAccount.balance : accountBalance;
      if (balance !== accountBalance && currentAccount) {
        setAccountBalance(currentAccount.balance);
      }
    };
    if (marketplace && marketplace.accountSelector && (svs.core.userSession.hasRole(roles.PLAYER) || svs.core.userSession.hasRole(roles.RETAILER))) {
      marketplace.accountSelector.helpers.observer.subscribe(onAccountSelectorDataChange, {
        emitCurrentValue: true
      });
    }
    return () => {
      marketplace.accountSelector.helpers.observer.unsubscribe(onAccountSelectorDataChange);
    };
  }, [accountBalance]);
  useEffect(() => {
    if (!marketplaceAccount || accountBalance !== marketplaceAccount.balance) {
      marketplace.accountSelector.helpers.observer.notify({
        drawNumber,
        productId,
        userAccountBalance: accountBalance
      });
    }
  }, [accountBalance, drawNumber, marketplaceAccount, productId]);
  useEffect(() => {
    marketplace.accountSelector.helpers.fetchGames({
      drawNumber,
      productId,
      options: {
        ignoreCache: true
      }
    }, err => {
      if (err) {
        logger.info('❌ account not connected, errorCode:', err.responseJSON.error.code);
        const shouldShowDialog = queryString.fromPlayTogether === 'true' || queryString.isRetailerAdmin === 'true';
        if (shouldShowDialog) {
          noAccountWarning();
        }
      }
    });
  }, [drawNumber, productId]);
  return {
    marketplaceAccount,
    accountBalance,
    hasMarketplaceAccount: accounts || marketplace.accountSelector.helpers.observer.getValue().accounts.length > 0
  };
};
setGlobal('svs.components.marketplace.accountSelector.useAccount', useAccount);

 })(window);