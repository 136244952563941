(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/account-selector/assets/javascripts/account-selector.js') >= 0) return;  svs.modules.push('/components/marketplace/account-selector/assets/javascripts/account-selector.js');

'use strict';

const {
  helpers
} = svs.components.marketplace.accountSelector;
const {
  USER_PLACEHOLDER
} = svs.components.marketplace.accountSelector.constants;
const AccountSelector = _ref => {
  let {
    accounts,
    productId,
    selectedId,
    userAccountBalance
  } = _ref;
  const addGroupIdToUrl = selectedId => {
    const defaultPath = document.location.origin + document.location.pathname;
    if (selectedId !== USER_PLACEHOLDER) {
      history.replaceState({}, '', "".concat(defaultPath, "?activatedDrawId=").concat(selectedId));
    } else {
      history.replaceState({}, '', defaultPath);
    }
    svs.utils.url.refreshQueryString();
  };
  const handleDone = selectedId => {
    addGroupIdToUrl(selectedId);
    helpers.observer.notify({
      accounts: helpers.observer.getValue().accounts
    });
  };
  if (!(accounts !== null && accounts !== void 0 && accounts.length) > 0) {
    return React.createElement("div", {
      "data-testid": "qa-account-selector-empty"
    });
  }
  return React.createElement("div", {
    "data-testid": "qa-account-selector"
  }, React.createElement(svs.components.marketplace.accountSelector.AccountsDropdown, {
    data: accounts,
    defaultSelected: selectedId,
    onDone: handleDone,
    productId: productId,
    selectedId: selectedId,
    userAccountBalance: userAccountBalance
  }));
};
setGlobal('svs.components.marketplace.accountSelector.AccountSelector', AccountSelector);

 })(window);