(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/account-selector/assets/javascripts/helpers/fetch-games.js') >= 0) return;  svs.modules.push('/components/marketplace/account-selector/assets/javascripts/helpers/fetch-games.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  userSession
} = svs.core;
const {
  observer
} = svs.components.marketplace.accountSelector.helpers;
const {
  services
} = svs.components.marketplaceData.accountSelector;
const cache = {};
setGlobal('svs.components.marketplace.accountSelector.helpers.fetchGames', (_ref, callback) => {
  let {
    drawNumber,
    productId,
    options = {}
  } = _ref;
  if (userSession.hasRole(userSession.roles.PLAYER)) {
    if (!options.ignoreCache && cache[productId] && cache[productId][drawNumber]) {
      observer.notify(cache[productId][drawNumber]);
      if (callback) {
        callback();
      }
      return;
    }
    services.fetchAvailableAccounts(drawNumber, productId, (err, accounts) => {
      if (err && options.avoidNotifyOnError && callback) {
        return callback(err);
      }
      if (err) {
        observer.notify({
          accounts: []
        });
      } else if (accounts) {
        cache[productId] = _objectSpread(_objectSpread({}, cache[productId]), {}, {
          [drawNumber]: {
            accounts
          }
        });
        observer.notify({
          accounts
        });
      }
      if (callback) {
        callback(err);
      }
    });
  } else if (userSession.hasRole(userSession.roles.RETAILER)) {
    if (!options.ignoreCache && cache[productId] && cache[productId][drawNumber]) {
      observer.notify(cache[productId][drawNumber]);
      if (callback) {
        callback();
      }
      return;
    }
    if (svs.utils.url.queryString.activatedDrawId) {
      services.fetchRetailAccount(svs.utils.url.queryString.activatedDrawId, (err, accounts) => {
        if (err && options.avoidNotifyOnError && callback) {
          return callback(err);
        }
        if (err) {
          observer.notify({
            accounts: []
          });
        } else if (accounts) {
          const currentAccount = svs.components.marketplace.accountSelector.helpers.getCurrentAccount();
          if (currentAccount && currentAccount.selectedCommissionPercent !== currentAccount.commissionPercent) {
            const accRightNow = accounts.find(acc => acc.activatedDrawId === currentAccount.activatedDrawId);
            accounts = accounts.filter(acc => acc.activatedDrawId !== currentAccount.activatedDrawId);
            const newBalance = Math.floor(accRightNow.soldShares * accRightNow.sharePrice / (1 + currentAccount.selectedCommissionPercent / 100)) || accRightNow.balance;
            accounts.push(_objectSpread(_objectSpread({}, accRightNow), {}, {
              selectedCommissionPercent: currentAccount.selectedCommissionPercent,
              balance: newBalance
            }));
          }
          cache[productId] = _objectSpread(_objectSpread({}, cache[productId]), {}, {
            [drawNumber]: {
              accounts
            }
          });
          observer.notify({
            accounts
          });
        }
        if (callback) {
          callback(err);
        }
      });
    }
  }
});

 })(window);