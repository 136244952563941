(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/account-selector/assets/javascripts/account-selector-container.js') >= 0) return;  svs.modules.push('/components/marketplace/account-selector/assets/javascripts/account-selector-container.js');
"use strict";

function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
const {
  useEffect,
  useState
} = React;
const {
  AccountSelector,
  helpers
} = svs.components.marketplace.accountSelector;
const {
  USER_PLACEHOLDER
} = svs.components.marketplace.accountSelector.constants;
const AccountSelectorContainer = props => {
  const [stateAccounts, setAccounts] = useState([]);
  const [stateDrawNumber, setDrawNumber] = useState(null);
  const [stateProductId, setProductId] = useState(null);
  const [stateSelectedId, setSelectedId] = useState(USER_PLACEHOLDER);
  const [stateUserAccountBalance, setUserAccountBalance] = useState(undefined);
  useEffect(() => {
    if (!props.userAccountBalance) {
      svs.components.account_balance.get(false, balance => {
        setUserAccountBalance(Number(balance));
      });
    }
  }, []);
  const handleDataUpdate = _ref => {
    let {
      accounts,
      drawNumber,
      productId,
      userAccountBalance
    } = _ref;
    const {
      activatedDrawId: selectedId
    } = helpers.getCurrentAccount() || {};
    setAccounts(accounts);
    setDrawNumber(drawNumber);
    setProductId(productId);
    if (selectedId) {
      setSelectedId(selectedId);
    } else {
      setSelectedId(USER_PLACEHOLDER);
    }
    if (userAccountBalance) {
      setUserAccountBalance(userAccountBalance.toString());
    } else {
      svs.components.account_balance.get(false, balance => {
        setUserAccountBalance(balance);
      });
    }
  };
  useEffect(() => {
    helpers.observer.subscribe(handleDataUpdate, {
      emitCurrentValue: true
    });
    return () => {
      helpers.observer.unsubscribe(handleDataUpdate);
    };
  }, []);
  return React.createElement(AccountSelector, _extends({
    accounts: stateAccounts,
    drawNumber: stateDrawNumber,
    productId: stateProductId,
    selectedId: stateSelectedId,
    userAccountBalance: stateUserAccountBalance
  }, props));
};
setGlobal('svs.components.marketplace.accountSelector.AccountSelectorContainer', AccountSelectorContainer);

 })(window);