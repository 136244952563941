(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/account-selector/assets/javascripts/pay-game.js') >= 0) return;  svs.modules.push('/components/marketplace/account-selector/assets/javascripts/pay-game.js');
"use strict";


const {
  getNewShares,
  showIncreaseWarning
} = svs.components.marketplace.accountSelector.helpers;
const {
  format
} = svs.utils;
const log = new svs.core.log.Logger('account-selector');
const payGame = (_ref, finishPayment) => {
  let {
    marketplaceAccount,
    drawNumber,
    productId
  } = _ref;
  if (!marketplaceAccount) {
    finishPayment();
    return;
  }
  if (new Date(marketplaceAccount.buyinCloseTime).getTime() < Date.now()) {
    finishPayment();
  } else {
    getNewShares({
      drawNumber,
      productId
    }, (error, _ref2) => {
      let {
        newShares,
        newBalance
      } = _ref2;
      if (!error && newShares > 0) {
        showIncreaseWarning(newShares,
        format.Currency(newBalance), finishPayment);
        return;
      }
      if (error) {
        log.warn('Could not fetch group, ', error);
      }
      finishPayment();
    });
  }
};
setGlobal('svs.components.marketplace.accountSelector.payGame', payGame);

 })(window);