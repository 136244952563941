(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/account-selector/assets/javascripts/accounts-dropdown.js') >= 0) return;  svs.modules.push('/components/marketplace/account-selector/assets/javascripts/accounts-dropdown.js');
"use strict";


const {
  Form,
  ControlGroup,
  Select,
  Option
} = svs.ui.reactForm;
const {
  format
} = svs.utils;
const {
  products
} = svs.utils;
const {
  getProductClassName
} = products;
const {
  USER_PLACEHOLDER
} = svs.components.marketplace.accountSelector.constants;
const AccountsDropdown = _ref => {
  let {
    data,
    onDone,
    selectedId,
    userAccountBalance,
    productId
  } = _ref;
  const handleOnChange = e => {
    onDone(e.target.value);
  };
  const accs = [...data, {
    activatedDrawId: USER_PLACEHOLDER,
    name: 'Mitt spelkonto',
    balance: userAccountBalance
  }];
  return React.createElement(Form, {
    className: "padding",
    "data-testid": "qa-account-selector-dropdown",
    isVertical: true
  }, React.createElement(ControlGroup, {
    className: getProductClassName(productId),
    v2: true
  }, React.createElement(Select, {
    branding: getProductClassName(productId),
    id: "reactSelect",
    label: "SPELKONTO",
    onChange: handleOnChange,
    v2: true,
    value: selectedId
  }, accs.map(acc => React.createElement(Option, {
    "data-testid": "account-selector-dropdown-option-".concat(acc.name),
    key: acc.activatedDrawId,
    value: acc.activatedDrawId
  }, acc.activatedDrawId === USER_PLACEHOLDER ? "".concat(acc.name, ": ").concat(format.Currency(acc.balance), " kr") : "".concat(acc.name, ": ").concat(format.Currency(acc.balance), " kr (").concat(format.Currency(acc.sharePrice), " kr)"))))));
};
setGlobal('svs.components.marketplace.accountSelector.AccountsDropdown', AccountsDropdown);

 })(window);