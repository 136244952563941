(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/account-selector/assets/javascripts/helpers/get-new-shares.js') >= 0) return;  svs.modules.push('/components/marketplace/account-selector/assets/javascripts/helpers/get-new-shares.js');

'use strict';

const convertToNumSharesRetail = (balance, sharePrice, commissionPercent) => Math.floor(balance * (commissionPercent / 100 + 1)) / sharePrice;
const convertToNumShares = (balance, sharePrice) => Math.floor(balance / sharePrice);
const getNewShares = (_ref, callback) => {
  let {
    drawNumber,
    productId
  } = _ref;
  const {
    fetchGames,
    getCurrentAccount
  } = svs.components.marketplace.accountSelector.helpers;
  const {
    balance: currentBalance,
    sharePrice: currentSharePrice,
    selectedCommissionPercent: commissionPercent,
    originalCommission
  } = getCurrentAccount();
  const currentShares = !commissionPercent && !originalCommission ? Math.floor(convertToNumShares(parseInt(currentBalance, 10), parseInt(currentSharePrice, 10))) : Math.floor(convertToNumSharesRetail(parseInt(currentBalance, 10), parseInt(currentSharePrice, 10), parseInt(commissionPercent || originalCommission, 10)));
  fetchGames({
    drawNumber,
    productId,
    options: {
      avoidNotifyOnError: true,
      ignoreCache: true
    }
  }, error => {
    if (error) {
      return callback(error, {});
    }
    const {
      balance: newBalance,
      sharePrice: newSharePrice,
      selectedCommissionPercent: newCommissionPercent,
      originalCommission: newOriginalCommissionPercent
    } = getCurrentAccount();
    const newShares = !newCommissionPercent && !newOriginalCommissionPercent ? Math.floor(convertToNumShares(parseInt(newBalance, 10), parseInt(newSharePrice, 10))) - currentShares : Math.floor(convertToNumSharesRetail(parseInt(newBalance, 10), parseInt(newSharePrice, 10), parseInt(newCommissionPercent || newOriginalCommissionPercent, 10))) - currentShares;
    callback(undefined, {
      newShares,
      newBalance
    });
  });
};
setGlobal('svs.components.marketplace.accountSelector.helpers.getNewShares', getNewShares);

 })(window);